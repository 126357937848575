<template>
  <Dialog
    :header="exibirHeader()"
    :visible="visible"
    :update="update"
    :closeOnEscape="!saving"
    :closable="!saving"
    :contentStyle="{ overflow: 'visible' }"
    :containerStyle="{ width: '70vw' }"
    :modal="true"
    @update:visible="fecharDialog()"
    @show="onShow()"
    @hide="onHide()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="nome" class="required">Nome</label>
              <InputText id="nome" v-model="grupoSeguranca.nome" type="text" />
              <div
                v-if="submitted && v$.grupoSeguranca.nome.required.$invalid"
                class="p-error">
                O campo Nome é obrigatório.
              </div>
            </div>
            <div class="field col-6 md:col-6">
              <label for="status">Roles</label>
              <MultiSelect
                v-model="rolesSelecionadas"
                :options="roles"
                optionLabel="nome"
                placeholder="Selecione a role"
                display="chip" />
              <div
                v-if="submitted && v$.rolesSelecionadas.required.$invalid"
                class="p-error">
                O campo Roles é obrigatório.
              </div>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fecharDialog()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import GrupoSeguranca from '@/domain/GrupoSeguranca.js'
import GrupoServiceService from '@/service/GrupoSegurancaService'
import RoleService from '@/service/RoleService'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsgruposeguranca: {
      type: Object,
      required: false,
      default: new GrupoSeguranca(),
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      grupoSeguranca: new GrupoSeguranca(),
      rolesSelecionadas: [],
      roles: [],
      saving: false,
      submitted: false,
    }
  },

  validations() {
    return {
      grupoSeguranca: {
        nome: { required },
      },
      rolesSelecionadas: {
        required,
      },
    }
  },
  created() {
    this.grupoSegurancaService = new GrupoServiceService(this.$http)
    this.roleService = new RoleService(this.$http)
  },

  mounted() {
    this.carregarRoles()
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Grupo' : 'Novo Grupo'
    },

    fecharDialog() {
      this.grupoSeguranca = {}
      this.rolesSelecionadas = []
      this.$emit('update:visible', false)
      this.$emit('update:update', false)
    },

    onShow() {
      this.submitted = false
      this.saving = false
      this.grupoSeguranca = JSON.parse(JSON.stringify(this.propsgruposeguranca))
      if (this.update) {
        this.rolesSelecionadas = this.grupoSeguranca.roles
      }
    },

    onHide() {},

    validate() {
      this.submitted = true
      this.v$.grupoSeguranca.$touch()

      if (
        !this.v$.grupoSeguranca.$invalid &&
        !this.v$.rolesSelecionadas.$invalid
      ) {
        return this.salvar()
      }
    },

    salvar() {
      this.grupoSeguranca.roles = this.rolesSelecionadas
      this.grupoSegurancaService
        .salvarGrupo(this.grupoSeguranca)
        .then(() => {
          this.fecharDialog()
          this.exibirToastSuccess('Grupo Segurança salvo com sucesso!')
        })
        .catch((err) => {
          this.exibirToastError(err)
        })
    },

    carregarRoles() {
      this.roleService
        .getAll()
        .then((res) => {
          this.roles = res
        })
        .catch((err) => {
          this.exibirToastError(err)
        })
    },

    exibirToastSuccess(message) {
      this.$toast.add({
        severity: 'success',
        summary: message,
        life: 10000,
      })
    },

    exibirToastError(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response.data.message,
        life: 10000,
      })
    },
  },
}
</script>
<style></style>
