export default class GrupoSegurancaService {
  constructor(http) {
    this._http = http
  }

  async getAll() {
    const { data } = await this._http.get('/api/grupos-seguranca/list')

    return data
  }

  async getOneByid(id) {
    const { data } = await this._http.get(`/api/grupos-seguranca/${id}`)

    return data
  }

  async salvarGrupo(data) {
    if (data.id) {
      return await this._http.put('/api/grupos-seguranca', data)
    } else {
      return await this._http.post('/api/grupos-seguranca', data)
    }
  }

  async delete(id) {
    return await this._http.delete(`/api/grupos-seguranca/${id}`)
  }
}
